select {
	background: transparent;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.form-row {
	display: flex; 
	flex-wrap: wrap;
	@screen md {
		.form-group {
			padding-left: 0;
			padding-right: 20px;
			&:nth-of-type(2n) {
				padding-left: 20px;
				padding-right: 0;
				label {
					left: 20px;
				}
			}
		}
	}
}

.form-group {
	@apply relative;

	label {
		@extend .body-copy;
		cursor: pointer;
		top: 0;
		xposition: absolute;
		transform: scale(0.8);
	}

	input {
		@extend .body-copy;
	}
}

.form-group--select {
	select {
		@apply border-b-1 border-black;
		width: 100%;
	}
}

.bg-brown{
	#search-bar {
	  input{ 
	  	 @apply text-offWhite;
	    &::placeholder{
	      @apply text-offWhite;
	    }
	  }
	  svg{
	  	*{
	  		fill:#fcfbf1;
	  	}
	  }
	}
}


#search-bar {
  button[type="submit"] {
    transform: translateX(0) translateY(-50%);
    transition: transform 0.2s ease;
    padding-left: 10px;
    &:hover {
      transform: translateX(5px) translateY(-50%);
    }
  }

  input{ 
    &::placeholder{
      @apply text-brown opacity-100;
    }
  }
}
.form-group--styled {
	@apply relative w-full pt-sm mb-0;

	label {
		@apply absolute transform translate-y-sm left-0 text-body text-black;
		transition: transform 0.4s ease;
		transform-origin: top left;
		top: 10px; 
		left: 0;
	}
	input,
	textarea,
	select {
		@apply bg-white w-full text-body px-0 py-xs;
		border-radius: 20px;
		@include placeholder() {
			@apply text-black text-body;
		}
	}
	textarea {
		height: 35px;
		resize: vertical;
	}
	&.active,
	&[data-has-value='true'] {
		label {
			transform: translateY(-5px) scale(0.8);
		}
	}
}
.form-group--simple {
	input {
		@apply border-b-1 border-navy bg-transparent rounded-none px-xs;
		width: inherit;
	}
	label {
		@apply absolute transform translate-y-sm left-0 text-body;
		transition: transform 0.4s ease;
		transform-origin: top left;
		top: 10px;
		left: 5px;
	}
}

.form-group--border {
	input {
		@apply border-1 border-navy bg-transparent rounded-none px-xs;
		width: inherit;
	}
	label {
		@apply absolute transform translate-y-sm left-0 text-body;
		transition: transform 0.4s ease;
		transform-origin: top left;
		top: 10px;
		left: 5px;
	}
	&.active,
	&[data-has-value='true'] {
		label {
			transform: translateY(-5px) scale(0.8);
		}
	}
}
.form-group--qty {
	input {
		@apply w-50;
	}
} 

.form-group--white {
	input {
		@apply bg-transparent border-b-1 w-full rounded-none ;

		&.text-brown{ 
			@include placeholder() {
				@apply text-brown;
			}
		}
		&.text-offWhite{
			@include placeholder() {
				@apply text-offWhite;
			}
		}
	}
	label {
		&.text-brown{
			@apply text-brown;
		}
		&.text-offWhite{
			@apply text-offWhite;
		}
		
	}
}

.form-group--inline {
	.form-group__field {
		@apply bg-transparent p-15 border-1 border-green-dark;
		border-right: 1px solid transparent;
		@include placeholder() {
			@apply text-brown;
		}
	}
	.form-group__append {
		@apply bg-transparent p-15 border-1 border-green-dark transition-colors  duration-200;
		border-left: 1px solid transparent;
		margin-left: -2px;

		&:hover {
			@apply bg-white text-green-mid border-offWhite;
		}
	}
}


#email-slideout{
	&.bg-brown{

		.form-group--checkbox {
			label {
				@apply text-offWhite;
			}
	
			.checkmark {
				border: 1px #FCFBF1 solid;
			}
		
			.checkbox-container input:checked ~ .checkmark {
				background-color: #FCFBF1;
			}
			
			.checkbox-container .checkmark:after {
				border: solid #735533;
			}
		}
	}
}



.form-group--checkbox {
	@apply relative w-full pt-10;

	label {
		@apply text-body text-brown;
		transition: transform 0.4s ease;
	}
	&.active,
	&[data-has-value='true'] {
		label {
			transform: translateY(0px) scale(0.8);
		}
	}
	/* The container */
	.checkbox-container {

	  display: block;
	  position: relative;
	  padding-left: 35px;
	  margin-bottom: 12px;
	  cursor: pointer;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}

	/* Hide the browser's default checkbox */
	.checkbox-container input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		@apply opacity-100 transition-all duration-300;
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 20px;
	  width: 20px;
	  border: 1px #735533 solid;
	  background-color: transparent;
	}

	/* On mouse-over, add a grey background color */
	.checkbox-container:hover input ~ .checkmark {
	  @apply opacity-80;
	}

	/* When the checkbox is checked, add a blue background */
	.checkbox-container input:checked ~ .checkmark {
	  background-color: #735533;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}

	/* Show the checkmark when checked */
	.checkbox-container input:checked ~ .checkmark:after {
	  display: block;
	}

	/* Style the checkmark/indicator */
	.checkbox-container .checkmark:after {
	  	left: 7px;
	    top: 3px;
	    width: 6px;
	    height: 12px;
	  border: solid #FCFBF1;
	  border-width: 0 3px 3px 0; 
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
	}
}


.checkboxes--pills {
	// .form-group,
	// .form-group--styled{
	// 	label{
	// 		position: relative;
	// 	}
	// 	&[data-has-value=true]{
	// 		label {
	// 			transform: scale(1);
	// 		}
	// 	}
	// }
	@apply flex flex-wrap;
	.checkbox {
		@apply cursor-pointer;
		padding: 0 5px;
		margin-bottom: 10px;
		display: inline-block;
	}
	input {
		display: none;
	}
	input[type='checkbox'] ~ label {
		@apply border-green-mid border-1 text-green-mid transition-colors duration-200 block cursor-pointer;
		border-radius: 20px;
		font-size: 1.5rem;
		padding: 10px 25px;
	}
	input[type='checkbox']:hover ~ label {
		@apply border-green-light bg-green-light text-green-mid;
	}
	input[type='checkbox']:checked ~ label {
		@apply bg-green-mid text-brown;
	}
}

.label--small,
.form-group--styled .label--small {
	transform: scale(0.8);
	transform-origin: left;
	display: block;
}

.select-box {
	display: flex;
	flex-direction: column;
}

.select-box .options-container {
	background-color: #fff;
	color: black;
	max-height: 0;
	width: 100%;
	opacity: 0;
	transition: all 0.4s;
	overflow: hidden;
	position: absolute;
	order: 1;
	top: 35px;
	left: 0;
	z-index: 20;
}

.selected-option {
	background: transparent;
	color: black;
	position: relative;

	order: 0;
	&:hover {
		opacity: 0.75;
	}
}

.selected-option::after {
	content: '';
	// background: url("https://assets/icons/chevron-down.svg");
	background-size: 12px 12px;
	background-repeat: no-repeat;

	position: absolute;
	height: 100%;
	width: 12px;
	right: 0px;
	top: 5px;
	transition: all 0.4s;
}

.select-box .options-container.active {
	max-height: 240px;
	opacity: 1;
	overflow-y: scroll;
}

.select-box .options-container.active + .selected::after {
	transform: rotateX(180deg);
	top: -6px;
}

.select-box .option,
.selected {
	padding: 5px 10px;
	cursor: pointer;
}

.select-box .option:hover {
	@apply bg-gray-200;
}

.select-box label {
	cursor: pointer;
}

.select-box .option .radio {
	display: none;
}

.formulate-input-errors {
	@apply text-left text-red;
	li {
		@apply text-body text-left;
	}
	.bg-red & {
		@apply text-red;
		li {
			@apply text-red;
		}
	}
}
.form-group--formulate {
	margin-bottom: 10px;
	.form-group--styled {
		margin-bottom: 5px;
	}
}
.StripeElement {
	border-bottom: 1px solid #fff;
}
.ElementsApp,
.ElementsApp .InputElement {
	padding: 10px;
}

.stripe-expiry {
	width: 100px;
}
.stripe-cvc {
	width: 50px;
}

#email-slideout{
	&.bg-brown{
		.select-dropdown {
			@apply text-offWhite;
	
			&__button {
				@apply bg-brown text-offWhite;

				.chevron-down {
				    border-top: 5px solid #FCFBF1;
				}
			}

			&__list {
				
				border: 1px solid #FCFBF1;
				
			}
			&__list-item {
				@apply bg-brown text-offWhite;
				
				border-top: 1px solid #FCFBF1;
				
				&:hover {
					background-color: #FCFBF1;
					color: #FCFBF1;
				}
			}
		}
	}
}



.select-dropdown {
	@apply w-full inline-block relative font-sohne font-normal text-body text-brown;
  	line-height: 18px; 
	margin: 0 15px 0 0;
	
	&::last-child {
		margin-right: 0px;
	}

	&__button {
		@apply bg-offWhite text-brown w-full;
		max-width: 100%;
		padding: 10px 0;
		border-radius: 3px;
		cursor: pointer;
		text-align: left;

		&::focus {
			outline: none;
		}

		.chevron-down {
			position: absolute;
			right: 0; 
			top: 15px;
			width: 0;
		    height: 0;
		    border-left: 5px solid transparent;
		    border-right: 5px solid transparent;
		    border-top: 5px solid #735533;
		}
	}

	&__list {
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		max-height: 300px;
		overflow: auto;
		margin: 0;
		padding: 0;
		border: 1px solid #735533;
		border-top: 0px solid transparent;
		list-style-type: none;
		opacity: 0;
		pointer-events: none;
		transform-origin: top left;
		transform: scale(1,0);
		transition: all ease-in-out .3s;
		z-index: 2;

		&.active {
			opacity: 1;
			pointer-events: auto;
			transform: scale(1,1);

		}
	}
	&__list-item {
		@apply bg-offWhite text-brown;
		display: block;
		list-style-type: none;
		padding: 10px 15px;
		border-top: 1px solid #735533;
		font-size: 14px;
		line-height: 1.4;
		cursor: pointer;
		text-align: left;
		transition: all ease-in-out .3s;

		&:hover {
			background-color: #735533;
			color: #FCFBF1;
			transition: all ease-in-out .3s;
		}
	}
}


// @import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);

// form {

//     input[type="text"],
//     input[type="email"]{
//       &:focus {
//         outline: 0;
//         background: white;
//       }
      
//       &:valid {
//       }
      
//       &:invalid:not(:focus):not(:placeholder-shown) {
//       }
      
//       &:invalid:not(:placeholder-shown) {
//         & ~ .requirements {
// 	    	opacity: 1;
//         }
//       }
      
//     }
//     .requirements {
//       opacity: 0;
//       transition: all ease-in-out .3s;
//       margin-top: 10px;
//     }
    
// }



