.card {
  max-width: 365px;
  transform: translateY(0);
  transition: transform cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
  will-change: transform;
  @apply pt-30 pb-25 px-25 flex flex-wrap flex-col items-start justify-between overflow-hidden;

  &:hover {
    transform: translateY(-4px);
  }
  a {
    display: block;
    width: 100%;
    cursor: pointer;
  }
  &__type {
    @apply uppercase mb-sm text-small font-reckless tracking-small w-full leading-standard inline-block;
  }
  &__title {
    @apply uppercase leading-standard;
  }
  &__image {
    @apply self-end mt-auto w-full h-full;
  }
  &__content {
    width: 100%;
  }

  .card__image-container {
    position: relative;
    width: 100%;
    // padding-bottom: 94%;
    overflow: hidden;
    align-self: flex-end;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%, -50%);
      transform-origin: top left;
      transition: transform 0.2s ease;
      object-fit: cover;
    }
  }

  .card__link-container:hover {
    .card__image-container {
      img {
        transform: scale(1.04) translate(-50%, -50%);
      }
    }
  }
  .card__image-container {
    .card__icon {
      width: 50px;
      height: 50px;
      bottom: 15px;
      top: unset;
      transform: translate(-50%, 0);
    }
  }
}

#vue-card-carousel {
  .swiper-slide {
    height: auto;
  }
}

.card-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .card {
    height: 100%;
    width: 100%;
    & + p {
      max-width: 300px;
    }
  }
}

.card__link-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.card--product {
  @apply p-0 h-full flex;

  .card__image-container {
    height: auto;
    margin-top: -1px;
    align-self: flex-start;
    @apply mb-15;
    img {
      top: 0;
      left: 0;
      height: auto;
      position: relative;
      transform: translate(0, 0);
    }
  }
  .card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.5);
    padding: 15px 15px 40px;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;

    transition: 0.2s all ease;
    opacity: 0;
    @apply flex;

    .card__blurb {
      @apply self-end w-full px-15 text-small;
    }
    .card__tags {
      @apply flex flex-wrap justify-end;
      .tag-pill {
        @apply mb-xs ml-xs;
      }
    }
  }
  &:hover {
    .card__overlay {
      opacity: 1;
    }
  }
  .card__content {
    @apply flex pb-40 h-full flex-wrap justify-center items-center flex-col;
  }
  .card__text-container {
    width: 100%;
    flex: 1;
    @apply flex w-full justify-between;

    p {
      @apply text-small;
    }
  }

  .card__title {
    max-width: 225px;
    @apply self-start;
  }
  .card__text {
    @apply mt-auto;
  }
  @media (max-width: 580px) {
    max-width: none;
  }
}

.image-container--journal {
  @media (max-width: 998px) {
    img {
      @include center;
      @apply w-full h-full object-cover;
    }
  }
}
