

.section-dropdown { 
	.chevron-wrap{
		top: 17px;
		position: absolute;
	    .chevron { 
	        position: absolute;
	        right: 5px;
	        width: 13px;
	        height: 7px;

	        transition: transform 300ms ease;
	        pointer-events: none;
	    }
    }
}

.section-dropdown{
	.js-dropdown__trigger{
		.js-scroll-to-hash-active{
			opacity: 0;
    		display: none;

    		&.active{
    			opacity: 1;
    			display: block;
    		}	
		}
	}
}