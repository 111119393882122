































































































.vue-dropdown { 
	@apply font-sohne text-body w-full text-brown;
	position: relative;
	z-index: 3;
	display: inline-block;
	min-width: 200px;
	transition: all 0.2s ease;

	.selected{
		z-index: 1;
		@apply border-1 border-brown px-20 py-10 border-brown text-brown text-detail font-sohnemed;

		&.bg-brown{ 
			@apply border-offWhite border-offWhite text-offWhite;
			ul{
				@apply border-1 border-t-0 border-offWhite; 
				li{
					@apply block text-detail font-sohne border-b-1 transition-all duration-300 px-20 py-10 border-offWhite text-offWhite bg-brown;

					&.active{
						@apply bg-offWhite text-brown;
					}
					&:hover,
					&:focus {
						@apply bg-offWhite text-offWhite;
					}
					&:nth-last-child(1){
						@apply border-b-0;
					}
				}
			}
			.chevron { 
				path {
				    stroke: #FCFBEE !important; 
				}
			}
		} 
	}
	ul {
		@apply border-1 border-t-0 border-brown;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 0;
		width: 100%;
		box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.07);
		cursor: pointer;
		display: none;
	}

	li {

		@apply block text-detail font-sohne border-b-1 transition-all duration-300 px-20 py-10 border-brown text-brown bg-offWhite;
		text-align: left;
		&.active{
			@apply bg-brown text-offWhite;
		}
		&:hover,
		&:focus {
			@apply bg-brown text-offWhite;
		}
		&:nth-last-child(1){
			@apply border-b-0;
		}
	}

	.chevron { 
		position: absolute;
		right: 5px;

		width: 13px;
		height: 7px;

		transition: transform 300ms ease;

		pointer-events: none;
		.isOpen & {
			.chevron { transform: rotate(-180deg); }
		}
	}
	.vue-filter &{
		li,
		.selected{
			@apply text-detail;
		}
	}
}
