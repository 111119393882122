
.styled-list{
	@apply ml-40  relative;
	list-style:none;
	counter-reset: recipe;

	li{
		counter-increment:recipe;
		@apply border-b-1 border-navy relative py-xs;
		&:last-of-type{
			@apply border-b-0;
		}
		&:before{
			@apply font-reckless text-h2 absolute -ml-40;
			content: counter(recipe);
			justify-content: center;
			align-items: center;
			left: 0;
			top:15px;
		}
	}
}
 

#page-tabs-initiatives{
  #page-switch{
    .page-switch__list{
      &.active{ 
        .page-switch__list--content{
          @apply bg-brown text-offWhite border-brown;
        }
      }
    }
  }
}

#page-tabs-knowledge,#page-filter{
  #page-switch{
    .page-switch__list{
      &.active{
        .page-switch__list--content{
          @apply bg-offWhite text-brown border-offWhite;
        }
      }
    }
  }
}


#page-tabs-initiatives{
  #page-switch{
    .page-switch__list{
      &.active{
        .page-switch__list--content{
          @apply bg-brown text-offWhite border-brown;
        }
      }
    }
  }
}


#page-filter{
  .page-switch__list{
    &:nth-child(1){
      display: none;
    }
  }


  .vue-dropdown{
    .vue-dropdown__option-list{
      li{
        &:nth-child(1){
          display: none;
        }
      }
    }
  }

}

.overview__items{
  &:nth-child(2){ 
    display: none;
  }
  & + .page__heading{
    display: none;
  }
}