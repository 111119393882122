* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-body;
}

p {
  @apply font-sohne font-normal text-body leading-standard;
  a {
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.7;
    }
  }
}
em {
  font-size: inherit;
}
.heading {
  @apply font-reckless uppercase leading-standard;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @apply font-reckless;
}
.h0 {
  @apply text-mob-h1  leading-standard;
  @screen md {
    @apply text-h1;
  }
  @screen lg {  
    @apply text-h0;
  }
}
h1,
.h1 {
  @apply text-mob-h1;
  line-height: 38px;
  @screen md {
    @apply text-50 leading-h1;
  }
  @screen md {
    @apply text-h1 leading-h1;
  }
}
h2,
.h2 {
  @apply text-mob-h2  leading-standard;
  @screen md {
    @apply text-h2;
  }
} 
h3,
.h3 {
  @apply text-mob-h3  leading-25;
  @screen md {
    @apply text-h3 leading-h3; 
  }
}

h4,
.h4 {
  @apply text-mob-h4  leading-standard;
  @screen md {
    @apply text-h4;
  }
}

h5,
.h5 {
  @apply text-mob-h5  leading-standard;
  @screen md {
    @apply text-h5;
  }
}

h6,
.h6 {
  @apply text-h6  leading-standard ;
  @screen md {
    @apply text-h6;
  }
}

.lead-text {
  @apply font-sohne font-normal text-body;
  line-height: 19.8px; 
  @screen sm {
    @apply text-lead;
  }
}

.body-copy,
.body-text {
  @apply font-sohne font-normal text-body;
  line-height: 18px; 
}

.body-textmed {
  @apply font-sohnemed font-normal text-body;
  line-height: 18px; 
  font-weight: 700;
}

.small-text {
  @apply font-sohnemed font-normal text-small;
  line-height: 13px; 
  font-weight: 700;
}

.link {
  @apply text-body underline;

  &--styled-underline {
    @apply no-underline text-body relative transition-opacity duration-200;
    padding-bottom: 5px;

    &::after {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      @apply bg-green-mid;
    }
    &.text-white {
      &::after {
        @apply bg-white;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-30;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply;
  }
  h4 {
    font-size: 30px;
  }
  p {
    @apply mb-sm;
    &:last-of-type {
      @apply mb-rg;
    }
  }
  &--reduced-spacing {
    li,
    p {
      &:last-of-type {
        @apply mb-sm;
      }
    }
  }
  &--no-spacing {
    li,
    p {
      &:last-of-type {
        @apply mb-0;
      }
    }
  }
  &--italic-u {
    em {
      font-style: normal;
      position: relative;
      &:after {
        content: "";
        height: 1px;
        width: 100%;
        @apply bg-navy block absolute left-0;
        bottom: -5px;
      }
    }
  }
  p,
  li {
    @extend .body-copy;
    color: inherit;
  }

  a {
    @apply underline;
    font-size: inherit;
    font-family: inherit;
    transition: opacity 0.2s ease;
    &.no-underline {
      @apply no-underline;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  ol:not(.styled-list),
  ul:not(.styled-list) {
    @apply pl-sm mb-sm;
    list-style: revert;
  }
}

.bold,
strong,
b {
  @apply font-bold font-sohnemed;
  font-size: inherit;
}

.small-caps {
  @apply uppercase text-small tracking-small;
}
