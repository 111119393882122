.info-slice {
  @apply bg-grey-light p-40 mb-lg;
  filter: drop-shadow(0px 7px 35px rgba(0, 0, 0, 0.1));
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  .three-things &{
    @apply mb-0 h-full;
  }
}
.member-tile {
  @apply w-fitFix h-fitFix;
  transition: all 0.2s ease;
  padding-bottom: 100%;
  cursor: pointer;
  overflow: hidden;
  display: block;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(#171717, 0.3);
    height: 100%;
    width: 100%;
    z-index: 30;
    opacity: 1;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0;
    }
  } 

  .member-tile__image {
    transition: 0.2s transform ease;
    transform: scale(1) translate(-50%, -50%);
  }
  .member-tile__bio {
    opacity: 0;
    transition: opacity 0.2s ease;
    @include center();
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  img {
    max-width: none;
  }
  &:hover {
    &:after {
      opacity: 0;
    }
    .member-tile__bio {
      opacity: 1;
    }
    .member-tile__image {
      transform: scale(1.02) translate(-50%, -50%);
    }
    .play-button {
      animation: fade-out 0.2s ease both;
    }
  }
  .play-button {
    z-index: 20;
  }
}

