.footer__link {
  @apply pb-15 block leading-standard font-reckless transition-colors duration-200;
  &--large {
    @apply pb-15 text-h4 font-reckless;
  }
  &:last-of-type {
    @apply pb-0;
  }
  &:hover {
    @apply text-red;
  }
}
@media (min-width: 998px) {
  .footer__link--large {
    @apply text-mob-h2  pb-30;
  }
}

@media (max-width: 998px) {
  .footer__link {
    @apply inline-block;
    width: 50%;
  }
  .footer__link--large {
    @apply pb-xs w-auto block;
  }
}


footer {
  svg{
    width: 23px;
    @screen sm {
      width: 45px;
    }
  }
}
