@import 'tailwindcss/base';

@import "glightbox/dist/css/glightbox.min";
@import "@glidejs/glide/src/assets/sass/glide.core"; 

//Type
@import 'typography/_font-loading.scss';
@import 'typography/_typography.scss';

// helpers

@import "utilities/mixins";
@import "utilities/animation";
@import "utilities/grid";
@import "utilities/buttons";
@import "utilities/globals";

@import "components/form";
@import "components/nav";
@import "components/footer";
@import "components/expand-collapse";

@import "components/card";
@import "components/modal";
@import "components/js-dropdown";
@import "components/js-classes";
@import "components/share-box";
@import "components/content-header";
@import "components/CardCarousel"; 
@import "components/slider";

@import "pages/about";
@import "pages/article";
@import "pages/account";
@import "pages/story";
@import "pages/checkout";

.pulse-cirlce {
  @apply inline-block rounded-full bg-white w-10 h-10 mr-xs relative z-10 overflow-visible;
  &:after {
    content: "";
    display: block;
    @apply bg-white rounded-full inline-block top-0 left-0 w-10 h-10 absolute;
    top: 50%;
    left: 50%;
    animation: pulse-circle 1.4s normal infinite;
    transform-origin: top left;
  }
}

@media (max-width: 768px) {
  .button-container {
    @apply justify-between;
    a[class^="btn"] {
      @apply mb-sm;
      display: inline-block; 
      min-width: 230px;
    }
  }
}

.gnext,
.gprev {
  display: none;
}

.slideout--open {
  padding-left: calc(100vw - 100%);
  overflow: hidden;
}
#search-trigger {
  path {
    transition: fill 0.2s ease;
  }
  &:hover {
    path {
    
    }
  }
}
