@import "swiper/swiper-bundle";

.vue-card-carousel {
  .swiper-slide {
    @apply flex justify-center py-15;
    height: auto !important;
  }
  .swiper-container {
    @apply pb-15;
  }
  .card-wrapper {
    @apply flex flex-wrap w-full flex-col items-center;
    cursor: grab;
    .card {
      flex: 1;
      width: 100%;
      &:not(.card--resources) .card__image-container {
        @screen md {
          width: 100%;
        }
      }
    }
    & > p {
      @apply mx-auto;
      max-width: 250px;
    }
  }
}
#related-card-carousel {
  @screen md {
    min-height: 500px;
  }
}
