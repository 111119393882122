@screen sm { 
  .story__content{
    &:nth-child(even){
      @apply pl-10;
    }
    &:nth-child(odd){
      @apply pr-10;
    }
  }
} 
 
.story__filter{
	.story__filter--item{
		&.active{
			@apply bg-brown text-offWhite border-offWhite;
		}
	}
}