#web{
  overflow: hidden scroll;
}

#search-bar{

  &.active{
    #search-trig {
        opacity: 0;
    }
    #search-input{
      width: 230px;
    }
  }  

}

#search-trig {
    pointer-events: none; 
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 25px;
    z-index: 2;
    opacity: 1;
    transition: all 300ms ease;
}

#search-input{
  width: 50px;
  transition: all 300ms ease;
}


.nav-is-open{

  .nav-trigger{
    span{
      &:nth-child(1){
      transform: translateY(6px) rotate(45deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
   .nav-backdrop{
    opacity: 1;
  }
}

  .nav-backdrop{ 
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    width: 100vw; 
    position: fixed;
    left: 0; 
    top: 0;
    background: rgba(0,0,0,0.4);
    z-index: 3; 
    transition: all 0.2s ease-out;
   
  } 


#main-nav {
  @apply w-full  flex flex-wrap items-start;
  transition: all 0.2s 0.1s ease-out;
  z-index: 20;

  .nav__logo{
      svg{
        width: 100%;
      }
    }
  @screen xs {
    .nav__logo{
      svg{
        width: auto;
      }
    }
  }
}

.nav__logo {
  display: block;
  overflow: hidden;
  width: 100%;
} 

.nav__link {
  @apply relative block;
  span {
    position: relative;
    display: block;
    @apply text-small;
    &:after {
      content: "";
      width: 0;
      height: 1px;
      background: #000;
      @apply block absolute bottom-0 left-0 transition-all duration-200;
    }
  }
  &.active,
  &:hover,
  &:focus {
    outline: none;
    span {
      &:after {
        @apply w-full;
      }
    }
  }
}

.nav__logo {
  
}

.bg-brown{ 

  .nav-trigger {

    span{
      @apply bg-offWhite;
    }
  }

}
.nav-trigger {
    width: 70px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3333333;
    @screen sm {
      width: 125px;
    }

  span{
    @apply bg-brown opacity-100;
    width: 30px;
    height: 1px;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  // &::after,
  // &::before {
  //   @apply bg-brown;

  //   content: "";
  //   width: 30px;
  //   height: 2px;
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   transition: all 0.2s ease-out;
  //   transform: rotate(0deg) transform(0, 0);
  // }

  &::after {
    top: 0;
    transform-origin: center;
  }
  &::before {
    top: 10px;
    transform-origin: center;
  }
}

.nav-trigger {
  z-index: 99;
  &::before {
    transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      background ease 0.15s;
  }

  &::after {
    transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      background ease 0.15s;
  }

  &.is-active {
    &::before {
      top: 5px;
      transform: rotate(-45deg);
      transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &::after {
      top: 5px;
      transform: rotate(45deg);
      transform-origin: center;
      transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}


  #main-nav {
    z-index: 50;

    .main-nav__bar {
      @apply top-0 right-0;
      position: fixed;
      top: 0;
      display: none;
      opacity: 0;
      visibility: hidden; 
      width: 100vw;
      height: 100vh;
      z-index: 5; 
      transition: all 0.2s ease-out;

      @screen md {
        width: 41.666667vw;
      }
   
    }
    .main-nav__dropdown {
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 100;
    }
    & .main-nav__list {
      width: 100%;
      .nav__link {
        span {
          @apply text-h3;
          &:after {
            bottom: -5px;
          }
        }
        @screen xl {
          span {
            @apply text-h4;
          }
        }
        &:after {
          @apply bg-offWhite;
        } 
      } 
    }

    &.is-open {
      @apply;

      .btn--getstarted{
        @apply text-brown border-brown;
          &:hover,
          &.active,
          &.loading { 
            @apply bg-white text-brown;
          }
      }

      .main-nav__bar {
        display: flex;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s 0.1s ease-out;
      }

      
    
    }
    &.is-animating {
      .main-nav__bar {
        display: flex;
        visibility: visible;
      }
    }
  }
  .nav-trigger { 
    display: block;
  }


.nav-sticky {
  @apply fixed text-black #{!important};
  @screen lg {
    .nav__link {
      &:after {
        @apply bg-black;
      } 
    }

  }
}

.nav-sticky--show {
  transform: translateY(0);
  transition: transform 0.2s ease-out;
}
[data-sticky="always"] {
  & + * {
      margin-top: 62px;
   
  }
}
.nav-sticky--hide {
  transform: translateY(-100px);
}
.dropdown {
  a {
    opacity: 0;
    transition: 0.2s 0s ease opacity;
  }
  &.open {
    a {
      opacity: 1;
      transition: 0.2s 0.4s ease opacity;
    }
  }
}
.dropown__link {
  margin-left: 10px;
  p {
    @apply transition-colors duration-200;
  }
  &:hover {
    p:first-of-type {
      @apply text-red;
    }
  }
}
