
@keyframes fade-in-show{

	0%{
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	1%{
		display: block;
		visibility: visible;
	}
	100%{
		opacity: 1;
	}

}

@keyframes fade-out-hide{
	0%{
		display: block;
		visibility: visible;
		opacity: 1;
	}
	99%{
		display: none;
		visibility: hidden;

	}
	100%{
		opacity: 0;
	}
}

.account-section{
	transition: 0.2s opacity ease;
	&.active{
		// animation: fade-in-show 0.2s ease both;
		opacity: 1;
	}
	&.inactive{
		// animation: fade-out-hide 0.2s ease both;
		opacity: 0;
	}
	&.is-visible{
		display: block;
	}
	&.is-hidden{
		display: none;
	}
}

.stripe-input{
	// border-bottom: 1px solid black;
	@apply border-b-1 border-black mb-25;
}


.table--orders{

	th,td{
		@apply font-normal;
	}
	th{
		@apply text-small py-xs  px-5;
		&:first-of-type{
			@apply pl-0;
		}
		&:last-of-type{
			@apply pr-0;
			max-width: 160px;
		}
	}
	td{
		@apply py-sm;

	}
	tr{
		@apply border-b-1 border-solid text-left ;
	}
}

.order-summary__row{
	@apply flex justify-between items-center;
}

ul.errors{

	margin-bottom: 15px;
	li{
		@apply text-red;
	}
}
.payment-form{
	.bg-red &,
	.bg-green-mid &{
		color: #fff;		
	}
}

.vue-payment-form-slideout{

	& > .btn{
		@extend .btn--white;
	}
	.bg-earth &{
		& > .btn{
			@extend .btn--primary;
		}
	}

}

.payment-form-fields{
	legend{
		@apply text-h6 mb-15;
	}	
	input{
		@apply border-b-1 bg-transparent mb-15 py-5;
		@include placeholder(){
			@apply text-black;
		}
	}
	.card-data{
		@apply mb-15;
	}
	
}

