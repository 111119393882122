.btn {
  @apply text-body
	px-45 
	py-xs 
	text-center 
	font-sohne
	duration-200 
	transition-colors 
	inline-flex 
	items-center 
	justify-center;
  min-width: 250px;

  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  & + .btn {
    @screen sm {
      @apply ml-xs;
    }
  }

  &[disabled="true"],
  &[disabled="true"]:hover,
  &[disabled="true"]:focus {
    opacity: 0.5 !important;
  }
  &.loading {
    color: transparent !important;
    position: relative;
    &:after {
      content: "";
      opacity: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px white solid;
      border-right: 2px transparent solid !important;
      animation: spin-centered 2s infinite both linear;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  &[disabled="true"],
  &[disabled="true"]:hover,
  &[disabled="true"]:focus {
    @apply opacity-50;
  }
}

.btn--primary {
  @apply bg-transparent text-brown  border-brown border-1;
  &:hover,
  &.loading,
  &.active {
    @apply text-offWhite bg-brown;
  }
}

.btn--inverse {
  @apply text-offWhite bg-brown border-brown border-1;
  &:hover,
  &.loading,
  &.active {
    @apply bg-transparent text-brown;
  }
  .bg-brown & {
    @apply text-offWhite border-offWhite;
    &:hover,
    &.loading,
    &.active {
      @apply text-brown bg-offWhite;
    }
  }
}

.btn--white {
  @apply bg-white text-brown border-1 border-white;
  &:hover,
  &.loading {
    @apply text-white bg-transparent;
    &:after {
      @apply border-white;
    }
  }
  .bg-transparent &,
  .bg-green-mid &,
  .bg-bg & {
    @apply text-green-mid;
    &:hover,
    &.loading,
    &.active {
      @apply text-green-dark  border-1 border-green-dark;
    }
  }
}

.btn--pill {
  @apply bg-transparent text-green-mid border-1 border-green-mid;
  font-size: 14px;
  padding: 5px 10px;
  min-width: 0;

  &:hover,
  &.loading,
  &.active {
    @apply bg-green-mid text-white;
    &:after {
      @apply border-white;
    }
  }
}

.btn--tab {
  @apply px-sm text-brown relative;
  min-width: 0;
  &:after {
    @apply bg-green-dark absolute;
    content: "";
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    transition: width 0.2s ease;
  }
  &:hover,
  &.active,
  &.loading {
    @apply text-green-dark;
    &:after {
      width: 100%;
    }
  }
}
