@font-face {
	font-family: 'sohne';
	src: url('../fonts/soehne-web-buch.woff2') format('woff');
	// url('../fonts/Gilroy Semibold/gilroy-semibold.woff') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'sohnemed';
	src: url('../fonts/soehne-web-kraftig.woff2') format('woff');
	// url('../fonts/Gilroy Semibold/gilroy-semibold.woff') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'reckless';
	src: url('../fonts/RecklessNeue-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
