.content-header {
  @apply relative overflow-hidden;
  min-height: 350px;
  .row {
    min-height: 350px;
  }
  p {
    @apply mx-auto;
    max-width: 475px;
  }

  &--image {
    p {
      max-width: 315px;
    }
  }
  @screen md {
    min-height: 450px;
    .row {
      min-height: 450px;
    }
  }
}
