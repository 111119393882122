* {
  -webkit-tap-highlight-color: transparent;
  overflow-anchor: none;
}

*:focus {
  outline: none;
}

*::selection {
  background: black;
  color: #fff;
}

body,
html {
  @apply font-sohne font-normal;
  width: 100%;
  font-size: 10px;

  &.scrolling {
    scroll-behavior: smooth;
  }
}

button {
  &:focus {
    box-shadow: 0;
    outline: 0 !important;
  }
}

[data-src],
[data-srcset] {
  transition: all 1s ease;
  transform-origin: center;
}

@responsive {
  .center-y {
    @include center("y");
  }
  .center-x {
    @include center("x");
  }
  .center {
    @include center();
  }
}

.play-button {
  cursor: pointer;
  svg{
     path{
      @apply transition-all duration-300;
     }
  }
  &:hover,
  a:hover & {
   svg{
    path{
      fill: #735533;
    }
   }
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.parallax-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @screen md {
    background-attachment: fixed;
  }
}

.general-hover {
  @apply opacity-100 transition-all duration-300;
  &:hover {
    @apply opacity-50;
  }
  &--light {
    @extend .general-hover;
    &:hover {
      @apply opacity-80;
    }
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  thead {
    display: none;
  }
  tr {
    @apply flex flex-col border-b-1 border-black pt-15;
  }
  th {
    @apply text-small font-normal;
    &:first-of-type {
      @apply pl-0;
    }
    &:last-of-type {
      @apply pr-0;
    }
  }
  td {
    @apply flex-col py-5 justify-start;
    &:first-of-type {
      @apply pl-0;
    }
    &:last-of-type {
      @apply pr-0;
    }
  }
  th,
  td {
    flex: 1;
    &[colspan="0"] {
      flex: 0;
    }
    &[colspan="2"] {
      flex: 2;
    }
    &[colspan="3"] {
      flex: 3;
    }
    &[colspan="4"] {
      flex: 4;
    }
  }
  @screen md {
    thead {
      @apply w-full text-small block;
    }
    tr {
      @apply flex-row pt-0;
    }
    th,
    td {
      @apply flex px-xs py-xs;
      min-width: 120px;
    }
  }

  &--prescription {
    tr {
      @apply justify-between border-b-1 border-grey-mid-light;
    }
    td,
    th {
      @screen md {
        max-width: 230px;
      }
    }
    th {
      @apply text-grey-mid-dark;
    }
    td {
      @apply items-start;
      @screen xs {
        .btn {
          min-width: 0;
        }
      }
      &:last-of-type {
        @apply pb-sm;
        @screen md {
          @apply pb-5;
        }
      }
    }
  }
}

.slideout-open {
  overflow-y: hidden; 
}

#TestimonialsCarousel {
  em {
    font-style: normal;
    position: relative;
    @apply inline-block;
    &:after {
      content: "";
      height: 1px;
      bottom: -2px;
      left: 0;
      @apply bg-navy w-full absolute;
    }
  }
}

.swiper--fade {
  @apply h-full;
  .swiper-container {
    @apply h-full;
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-pagination-bullet {
    height: 2px;
    width: 22px;
    border-radius: 0;
    background-color: white;
  }
}

.tag-pill {
  letter-spacing: 1px;
  @apply rounded-full bg-green-mint py-5 px-15  text-navy border-1 uppercase border-green-mint text-tiny;

  &--white {
    @apply bg-white text-navy border-white;
  }
  &--outline {
    @apply bg-transparent text-white border-white;
  }
}


.general-hover {
  @apply opacity-100 transition-all duration-300;
  &:hover {
    @apply opacity-50;
  }
  &--light {
    @extend .general-hover;
    &:hover {
      @apply opacity-80;
    }
  }
}

 
.arrow-hover{
  @screen sm {
    .arrow{
      @apply opacity-0 transition-all duration-300;
    }
    &:hover{
      .arrow{
        @apply opacity-100;
      }
    }
  }
}


// .portrait-container{
// 	@media(max-width: 580px){
// 		height: 0;
// 		padding-bottom: 56.25%;

// 		img{
// 			position: absolute;

// 		}
// 	}
// }
