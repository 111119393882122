.glide--slider{
	.glide__slide{
		@apply bg-offWhite text-brown duration-300 transition-all;
		&.glide__slide--active{
			@apply bg-brown text-offWhite;
		}
	}
}  

.glide--gallery{
	.glide__slide{
		.slider__content{
			@screen md {
			@apply opacity-0;
				&:hover{
					@apply opacity-100;
				}
			}
		}
	} 
}  

.glide--slider{
	.controls{
		.glide__arrow{
			@screen sm {
				&--right{
					right: 0;
					cursor: url("https://homefoundation.org.nz/images/icons/right-arrow.svg"), auto;
				}

				position: absolute; 
				top: 0;
				width: 100%;
				height: 100%;

			}
		} 
	}

}