.share-box {
  position: relative;
  width: 100%;
  height: 30px;

  // button {
  // 	position: absolute;
  // 	z-index: 1;
  // }

  .share-icons {
    @apply flex;
    text-align: center;
    align-items: center;

    @screen md {
      @apply ml-auto;
    }
    @screen lg {
      display: block;
      max-width: 50px;
    }
    li {
      transition: background-color 0.2s ease;
      margin-left: 0;
      display: inline-block;
      margin-left: 30px;
      a {
        transform: filter 0.2s ease;
        filter: invert(0);
      }
      &:not(.title):hover {
        background-color: black;
        a {
          filter: invert(1);
        }
      }
      &:first-of-type {
        margin-left: 20px;
      }
      &:not(:first-of-type) {
        @apply rounded-full border-1 border-solid flex items-center justify-center;
        width: 35px;
        height: 35px;
        @screen lg {
          @apply mx-auto;
        }
      }

      @screen lg {
        @apply mb-sm ml-0;
        &:first-of-type {
          @apply mb-xs ml-0;
        }
      }
    }
  }

  .twitter,
  .facebook,
  .email {
    @apply mx-auto;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 50%;
    display: block;

    height: 20px;
    opacity: 0;
    transition: all 300ms ease;
    width: 24px;
  }

  .twitter {
    background-image: url("../icons/twitter.svg");
  }
  .facebook {
    background-image: url("../icons/facebook.svg");
    transition-delay: 50ms;
  }
  .email {
    background-image: url("../icons/email.svg");
    background-size: 30px;
    width: 30px;
    transition-delay: 100ms;
  }

  &:hover,
  &.active {
    .twitter,
    .facebook,
    .email {
      opacity: 1;
      // transform: translateX(60px);
    }
  }
}
